import React from "react"
import Layout from "../../components/layout"

import { Helmet } from "react-helmet"
import { Container, Row, Col, Nav } from "react-bootstrap"

const Articles = () => {
  return (
    <>
      <Helmet bodyAttributes={{ class: "info" }} />
      <Layout>
        <Container className="info-contain">
          <Row>
            <Col lg={10}>
              <h1 className="page-title">Publications</h1>
              <div className="publication-section">
                <Nav variant="tabs" defaultActiveKey="/publications/articles">
                  <Nav.Item>
                    <Nav.Link href="/publications/all">All</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/articles">Articles</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/chapters">Chapters</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/publications/patents">Patents</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <p className="resume-text">
                <span className="bold author">ARTICLES</span>
              </p>

              <div className="publication-unit">
                <p className="publication-title">
                  [36] Detection of a pederin-like compound using a
                  dilution-to-extinction-based platform for the isolation of
                  marine bacteria in drug discovery strategies
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> X. Benitez,{" "}
                  <span className="author">E. G. Gonzalez,</span> J. Garcia, P.
                  Zuñiga, F. de la Calle &amp; C. Cueva
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2021 in{" "}
                  <span className="italic">Microbial Biotechnology</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1111/1751-7915.13679
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [35] Identification of trans-AT polyketide clusters in two
                  marine bacteria reveals cryptic similarities between distinct
                  symbiosis factors
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> D. Kačar, L.M.
                  Cañedo, P. Rodríguez,{" "}
                  <span className="author">E. G. Gonzalez,</span> B. Galán, C.
                  Schleissner, S. Leopold-Messer, J. Piel, C. Cuevas, F. de la
                  Calle, J. L. García
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2021 in
                  Environmental Microbiology
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1111/1462-2920.15470
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [34] Isolation of{" "}
                  <span className="italic">Labrenzia alexandrii</span> strains
                  producing a pederin-like compound using a dilution to
                  extinction methodology in 384 microplate format
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> X. Benitez, J.
                  Garcia, P. Zuñiga,{" "}
                  <span className="author">E. G. Gonzalez,</span> F. de la Calle
                  &amp; C. Cueva
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2020 in Marine
                  Drugs. Meeting Abstract. 1:8. publons.com/p/34007676/
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [33] Influence of historical and human factors on genetic
                  structure and diversity patterns in peripheral populations:
                  implications for the conservation of Moroccan trout
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> S. Perea, M. Al
                  Mouri, <span className="author">E. G. Gonzalez,</span> L.
                  Alcaraz, A. Yahyaoui &amp; I. Doadrio
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2020 in{" "}
                  <span className="italic">BioRxiv</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1101/2020.04.06.027219
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [32] Lack of genetic differentiation between two sympatric
                  species of <span className="bold-italic">Astyanax</span>{" "}
                  (Characidae:Teleostei) in Lake Catemaco, Mexico
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> P. Ornelas,{" "}
                  <span className="author">E. G. Gonzalez,</span> D. Tautz &amp;
                  I. Doadrio
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2020 in{" "}
                  <span className="italic">Resaearch Square</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.21203/rs.2.22839/v1
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [31] Author correction: Gene-associated markers provide tools
                  for tackling illegal fishing and false eco-certification
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> E. E. Nielsen, A.
                  Cariani, E. M. Aoidh, G. E. Maes, I. Milano,{" "}
                  <span className="author">E. G. Gonzalez,</span> et al
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2019 in{" "}
                  <span className="italic">Nature Communications</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1038/S41467-019-13399-5
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [30] Phylogeography and population genetic analyses in the
                  Iberian toothcarp{" "}
                  <span className="bold-italic">(Aphanius iberus</span>{" "}
                  Valenciennes, 1846) at different time scales
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez,</span> C. Cunha, H.
                  R. Ghanavi. F. J. Oliva-Paterna, M. Torralva &amp; I. Doadrio
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2018 in{" "}
                  <span className="italic">Journal of Heredity</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span> 10.1093/JHERED/ESX076
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [29] Phylogenetic relationships of freshwater fishes of the
                  genus <span className="bold-italic">Capoeta</span>{" "}
                  (Actinopterygii, Cyprinidae) in Iran
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> H. R. Ghanavi,{" "}
                  <span className="author">E. G. Gonzalez</span> &amp; I.
                  Doadrio
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2016 in{" "}
                  <span className="italic">Ecology and Evolution</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span> 10.1002/ECE3.2411
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [28] Genetic and demographic recovery of an isolated
                  population of brown bear{" "}
                  <span className="bold-italic">Ursus arctos</span> L., 1758
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez,</span> J. C. Blanco,
                  F. Ballesteros, L. Alcaraz, G. Palomero &amp; I. Doadrio
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2016 in{" "}
                  <span className="italic">Peer Journal</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span> 10.7717/PEERJ.1928
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [27] Comparative population genetic structure and demographic
                  patterns of two killifish species (
                  <span className="bold-italic">
                    Aphanius baeticus and Aphanius iberus
                  </span>
                  ) at the Iberian Peninsula
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez,</span> C. Pedraza, C.
                  Cunha, F. J. Oliva-Paterna, M. Torralva &amp; I. Doadrio
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2015 in{" "}
                  <span className="italic">Frontiers Marine Science</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.3389/CONF.FMARS.2015.03.00084
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [26] Patterns of genetic variation of the endangered European
                  mink (Mustela lutreola L., 1761)
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> M. T. Cabria,{" "}
                  <span className="author">E. G. Gonzalez,</span> B. J.
                  Gómez-Moliner, J. R. Michaux &amp; R. Zardoya
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2015 in{" "}
                  <span className="italic">BMC Evolutionary Biology</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1186/S12862-015-0427-9
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [25] Contrasting demographic history and gene flow patterns of
                  two mangrove species on either side of the Central American
                  Isthmus
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> I. Cerón-Souza,{" "}
                  <span className="author">E. G. Gonzalez,</span> A.
                  Schwarzbach, D. Salas-Leyva, E. Rivera-Ocasio, N. Toro-Perea,
                  E. Bermingham &amp; W. O. McMillan
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2015 in{" "}
                  <span className="italic">Ecology and Evolution</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span> 10.1002/ECE3.1569
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [24] Comparative pattern of genetic structure in two
                  Mediterranean killifishes (
                  <span className="bold-italic">Aphanius fasciatus</span> and{" "}
                  <span className="bold-italic">Aphanius iberus</span>) inferred
                  from both mitochondrial and nuclear data
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> A. M. Pappalardo,{" "}
                  <span className="author">E. G. Gonzalez,</span> C. Tigano, I.
                  Doadrio &amp; V. Ferrito
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2015 in{" "}
                  <span className="italic">Journal of Fish Biology</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span> 10.1111/JFB.12693
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [23] Island survivors: population genetic structure and
                  demography of the critically endangered giant lizard of La
                  Gomera, <span className="bold-italic">Gallotia bravoana</span>
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez,</span> I.
                  Cerón-Souza, J. A. Mateo &amp; R. Zardoya
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2014 in{" "}
                  <span className="italic">BMC Genetics</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1186/S12863-014-0121-8
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [22] Genetic diversity and population history of the
                  endangered killifish (
                  <span className="bold-italic">Aphanius baeticus</span>{" "}
                  Doadrio, Carmona &amp; Fernández-Delgado, 2002)
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez,</span> C. Pedraza
                  &amp; I. Doadrio
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2014 in{" "}
                  <span className="italic">Journal of Heredity</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span> 10.1093/JHERED/ESU034
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [21] Development and characterization of twelve new
                  polymorphic microsatellite loci in the Iberian Ribbed newt,{" "}
                  <span className="bold-italic">Pleurodeles waltl</span>{" "}
                  (Caudata: Salamandridae), with data on cross- amplification in{" "}
                  <span className="bold-italic">P. nebulosus</span>
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> J. Gutierrez,{" "}
                  <span className="author">E. G. Gonzalez</span> &amp; I.
                  Martínez-Solano
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2014 in{" "}
                  <span className="italic">Amphibia Reptilia</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1163/15685381-00002926
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [20] Gene-associated markers provide tools for tackling
                  illegal fishing and false eco-certification
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> E. E. Nielsen, A.
                  Cariani, E. M. Aoidh, G. E. Maes, I. Milano, (&amp;#8230;),{" "}
                  <span className="author">E. G. Gonzalez,</span> et al
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2013 in{" "}
                  <span className="italic">Nature Communications</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span> 10.1038/ncomms1845
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [19] High levels of relatedness between Brown-headed cowbird
                  nestmates in a heavily-parasitized host community
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> J. W. Rivers, S.
                  Young, <span className="author">E. G. Gonzalez,</span> B.
                  Horton, &amp; R. C. Fleischer
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2012 in{" "}
                  <span className="italic">The Auk</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1525/AUK.2012.11236
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [18] Evolutionary history of the genus{" "}
                  <span className="bold-italic">Trisopterus</span>
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez,</span> R. L. Cunha,
                  R. G. Sevilla, H. R. Ghanavi, G. Krey &amp; J. M. Bautista
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2012 in{" "}
                  <span className="italic">
                    Molecular Phylogenetics and Evolution
                  </span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1016/J.YMPEV.2011.11.032
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [17] Improving collection and sharing of information on
                  aquatic genetic resources (AqGR) for food and agriculture
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> J. Benzie, S.
                  Abura, N. Bailly, D. Bartley, J. M. Bautista, R. Brummett, L.
                  Collette, M. A. Encinas, R. Garcia,{" "}
                  <span className="author">E. G. Gonzalez,</span> et. al.
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2011 at{" "}
                  <span className="italic">FAO</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">URL:</span>{" "}
                  http://www.fao.org/3/am647e/am647e.pdf
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [16] Quaternary geographical sibling speciation and population
                  structuring in the Eastern Atlantic skates (suborder Rajoidea){" "}
                  <span className="bold-italic">Raja clavata</span> and{" "}
                  <span className="bold-italic">R. straleni</span>
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> P. Pasolini, C.
                  Ragazzini, Z. Zacaro, A. Cariani, G. Ferrara,{" "}
                  <span className="author">E. G. Gonzalez,</span> M. Landi, I.
                  Milano &amp; F. Tinti
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2011 in{" "}
                  <span className="italic">Marine Biology</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1007/S00227-011-1722-7
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [15] Isolation and characterization of novel polymorphic
                  microsatellite loci for the white-clawed crayfish,{" "}
                  <span className="bold-italic">Autropotamobius italicus</span>{" "}
                  (Faxon, 1914)
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> C. Pedraza-Lara,{" "}
                  <span className="author">E. G. Gonzalez,</span> P. Bloor &amp;
                  I. Doadrio
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2007 in{" "}
                  <span className="italic">Molecular Ecology Notes</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1111/J.1471-8286.2006.01640.X
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [14] Population proteomics of the European hake (
                  <span className="bold-italic">Merluccius merluccius</span>)
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez,</span> G. Krey, M.
                  Espiñeira, A. Diez, A. Puyet &amp; J. M. Bautista
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2010 in{" "}
                  <span className="italic">Journal of Proteome Research</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span> 10.1021/PR100683K
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [13] Polymorphic microsatellite markers for the critically
                  endangered Balearic shearwater,{" "}
                  <span className="italic">Puffinus mauretanicus</span>{" "}
                  (Procellariidae)
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez,</span> M. Genovart,
                  D. Oro, R. Zardoya &amp; J. Juste
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2009 in{" "}
                  <span className="italic">Molecular Ecology Resources</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1111/J.1755-0998.2009.02563.X
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [12] Patterns of gene flow and source-sink dynamics in high
                  altitude populations of the common toad{" "}
                  <span className="bold-italic">Bufo bufo</span> (Anura,
                  Bufonidae)
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> I.Martínez-Solano
                  &amp; <span className="author">E. G. Gonzalez</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2008 in{" "}
                  <span className="italic">
                    Biological Journal of the Linnean Society
                  </span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1111/J.1095-8312.2008.01098.X
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [11] Genetic structuring and migration patterns of Atlantic
                  bigeye tuna (
                  <span className="bold-italic">Thunnus obesus</span>)
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez,</span> P. Beerli
                  &amp; R. Zardoya
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2008 in{" "}
                  <span className="italic">BMC Evolutionary Biology</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1186/1471-2148-8-252
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [10] As the raven flies: using genetic data to infer the
                  history of invasive common raven (
                  <span className="bold-italic">Corvus corax</span>) populations
                  in the Mojave Desert
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> R. C. Fleischer,
                  W. I. Boarman, <span className="author">E. G. Gonzalez,</span>{" "}
                  A. Godines, K. Omland, S. Young &amp; C. E. Mcintosh
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2008 in{" "}
                  <span className="italic">Molecular Ecology</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1111/J.1365-294X.2007.03532.X
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [09] Relative role of life-history traits and historical
                  factors in shaping genetic population structure of sardines (
                  <span className="bold-italic">Sardina pilchardus</span>)
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez</span> &amp; R.
                  Zardoya
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2007 in{" "}
                  <span className="italic">BMC Evolutionary Biology</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1186/1471-2148-7-197
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [08] Microsatellite markers for the endangered European mink (
                  <span className="bold-italic">Mustela lutreola</span>) and
                  closely related mustelids
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> M. T. Cabria,{" "}
                  <span className="author">E. G. Gonzalez,</span> B. J.
                  Gómez-Moliner &amp; R. Zardoya
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2007 in{" "}
                  <span className="italic">Molecular Ecology Notes</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1111/J.1471-8286.2007.01825.X
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [07] Isolation and characterization of polymorphic
                  microsatellites for the sardine,{" "}
                  <span className="bold-italic">Sardina pilchardus</span>{" "}
                  (Clupeiformes: Clupeidae)
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez</span> &amp; R.
                  Zardoya
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2007 in{" "}
                  <span className="italic">Molecular Ecology Notes</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1111/J.1471-8286.2006.01640.X
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [06] Novel highly polymorphic loci and cross-amplified
                  microsatellites for the lesser kestrel,{" "}
                  <span className="bold-italic">Falco naumanni</span>
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> J. Ortego,{" "}
                  <span className="author">E. G. Gonzalez,</span> I. Sánchez, J.
                  M. Aparicio &amp; P. J. Cordero
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2007 in{" "}
                  <span className="italic">Ardeola</span>
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [05] Signature of an early genetic bottleneck in a population
                  of Moroccan sardines (
                  <span className="bold-italic">Sardina pilchardus</span>)
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> T. Atarhouch, L.
                  Rüber, <span className="author">E. G. Gonzalez,</span> E.
                  Albert, M. Rami, A. Dakkak &amp; R. Zardoya
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2006 in{" "}
                  <span className="italic">
                    Molecular Phylogenetics and Evolution
                  </span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1016/J.YMPEV.2005.08.003
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [04] Genetic diversity and historical demography of Atlantic
                  bigeye tuna (
                  <span className="bold-italic">Thunnus obesus</span>)
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span> P. Martínez,{" "}
                  <span className="author">E. G. Gonzalez,</span> R. Castilho
                  &amp; R. Zardoya
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2006 in{" "}
                  <span className="italic">
                    Molecular Phylogenetics and Evolution
                  </span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1016/J.YMPEV.2005.07.022
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [03] Novel polymorphic microsatellites for the red-legged
                  partridge (<span className="bold-italic">Alectoris rufa</span>
                  ) and cross-species amplification in{" "}
                  <span className="bold-italic">Alectoris graeca</span>
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez,</span> A. M. Castilla
                  &amp; R. Zardoya
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2005 in{" "}
                  <span className="italic">Molecular Ecology Notes</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">DOI:</span>{" "}
                  10.1111/J.1471-8286.2005.00960.X
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [02] Microsatélites: sus aplicaciones en la conservación de la
                  Biodiversidad.
                </p>
                <p className="publication-text">
                  <span className="text-muted">Authors:</span>{" "}
                  <span className="author">E. G. Gonzalez</span>
                </p>
                <p className="publication-name">
                  <span className="text-muted">Published:</span> 2003 in{" "}
                  <span className="italic">Graellsia</span>
                </p>
              </div>

              <div className="publication-unit">
                <p className="publication-title">
                  [01] Thesis dissertation: Population genetics of two marine
                  pelagic fishes European sardine,{" "}
                  <span className="bold-italic">"Sardina pilchardus"</span>{" "}
                  (Walbaum, 1792) and bigeye tuna,{" "}
                  <span className="bold-italic">"Thunnus obesus"</span> (Lowe,
                  1839).
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Articles
